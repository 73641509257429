import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { SharedService } from "src/app/services/shared.service";

import { PidMappingsService } from "../pid-mappings.service";
import { PIDMappingProfile } from "../pid-mapping";
import { TitleService } from "../../../services/title.service";
import { FormControl, UntypedFormControl, Validators } from "@angular/forms";
import { isEqual, omit } from "lodash";

interface PIDRule {
    id: number;
    action: "pass" | "set_null" | "remove" | "map" | "map_range";
    program_number: number;
    original_pid?: string;
    category?: string;
    type?: string;
    new_pid: number;
    new_program_number?: number;
}

interface PCRRule {
    program_number: number;
}

@Component({
    selector: "app-pid-mapping-form",
    templateUrl: "./pid-mapping-form.component.html"
})
export class PidMappingFormComponent implements OnInit, OnDestroy {
    pidMappingProfile: PIDMappingProfile;
    pidMappingProfileName: string;
    pidMappingProfileNames: string[];
    action: string;

    submitted = false;
    minLength = 2;
    isEdit = false;
    isClone = false;

    loading = true;
    saving = false;

    constants = Constants;
    categories = Constants.pidMapping.categories;
    types = Constants.pidMapping.types;

    actions = [
        { name: "Pass", value: "pass" },
        { name: "Set Null", value: "set_null" },
        { name: "Remove", value: "remove" },
        { name: "Map", value: "map" },
        { name: "Map Range", value: "map_range" }
    ];

    epid_mapping_actions = [
        { name: "Pass", value: "pass" },
        { name: "Set Null", value: "set_null" },
        { name: "Remove", value: "remove" },
        { name: "Map", value: "map" }
    ];

    sptsPMTActions = [
        { name: "Pass", value: "pass" },
        { name: "Map", value: "map" }
    ];

    elementaryPIDRules: PIDRule[];
    elementaryPIDDefault = "";
    streamCategoryRules: PIDRule[];
    streamTypeRules: PIDRule[];
    pmtRules: PIDRule[] = [];
    program: string;
    //
    pcrOnVideo: number;
    pcrRules: PCRRule[] = [];

    private pidMappingProfilesSubscription: Subscription;

    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new FormControl<string>("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.name)
    ]);

    duplicateRulesError = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private pms: PidMappingsService,
        private sharedService: SharedService,
        private titleService: TitleService
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.pidMappingProfileName = params.get("name");
            this.action = params.get("action");
            if (this.pidMappingProfileName) {
                this.pidMappingProfile = Object.assign(
                    {},
                    this.pms.getCachedPIDMappingProfile(this.pidMappingProfileName)
                );
                // Check if found in cache
                if (this.sharedService.isEmptyObject(this.pidMappingProfile)) {
                    this.pms
                        .refreshPIDMappingProfiles(true)
                        .pipe(take(1))
                        .subscribe(() => {
                            this.pidMappingProfile = Object.assign(
                                {},
                                this.pms.getCachedPIDMappingProfile(this.pidMappingProfileName)
                            );
                            this.prepForm();
                            this.loading = false;
                        });
                } else {
                    this.loading = false;
                }
            } else {
                this.loading = false;
            }
        });
    }

    prepForm() {
        if (this.isClone || this.isEdit) {
            this.tagsControl.setValue(this.pidMappingProfile.resourceTags);
            if (this.isEdit) {
                this.nameControl.setValue(this.pidMappingProfile.name);
            }
            if (this.isClone) {
                this.pidMappingProfile.name = null;
            }
            if (this.pidMappingProfile && this.pidMappingProfile.rules) {
                this.pmtRules = [];
                if (this.pidMappingProfile.type === "category") {
                    this.streamCategoryRules = [];
                    this.pidMappingProfile.rules.forEach(rule => {
                        if (rule.source !== "pmt")
                            this.streamCategoryRules.push({
                                id: rule.id,
                                action: rule.action,
                                program_number: rule.program_number,
                                category: rule.source,
                                new_pid: rule.target_pid
                            });
                        else
                            this.pmtRules.push({
                                id: rule.id,
                                action: rule.action,
                                program_number: rule.program_number,
                                new_pid: rule.target_pid,
                                new_program_number: rule.target_program_number
                            });
                    });

                    this.elementaryPIDRules = [
                        {
                            id: 1,
                            action: this.pidMappingProfile.default_action,
                            program_number: null,
                            original_pid: null,
                            new_pid: null
                        }
                    ];
                    this.streamTypeRules = [
                        {
                            id: 1,
                            action: this.pidMappingProfile.default_action,
                            program_number: null,
                            type: "mpgv1",
                            new_pid: null
                        }
                    ];
                }
                if (this.pidMappingProfile.type === "type") {
                    this.streamTypeRules = [];
                    this.pidMappingProfile.rules.forEach(rule => {
                        if (rule.source !== "pmt")
                            this.streamTypeRules.push({
                                id: rule.id,
                                action: rule.action,
                                program_number: rule.program_number,
                                type: rule.source,
                                new_pid: rule.target_pid
                            });
                        else
                            this.pmtRules.push({
                                id: rule.id,
                                action: rule.action,
                                program_number: rule.program_number,
                                new_pid: rule.target_pid,
                                new_program_number: rule.target_program_number
                            });
                    });

                    this.elementaryPIDRules = [
                        {
                            id: 1,
                            action: this.pidMappingProfile.default_action,
                            program_number: null,
                            original_pid: null,
                            new_pid: null
                        }
                    ];
                    this.streamCategoryRules = [
                        {
                            id: 1,
                            action: this.pidMappingProfile.default_action,
                            program_number: null,
                            category: "video",
                            new_pid: null
                        }
                    ];
                }
                if (this.pidMappingProfile.type === "pid") {
                    this.elementaryPIDRules = [];
                    this.pidMappingProfile.rules.forEach(rule => {
                        if (rule.source !== "pmt")
                            this.elementaryPIDRules.push({
                                id: rule.id,
                                action: rule.action,
                                program_number: rule.program_number,
                                original_pid: rule.source,
                                new_pid: rule.target_pid
                            });
                        else
                            this.pmtRules.push({
                                id: rule.id,
                                action: rule.action,
                                program_number: rule.program_number,
                                new_pid: rule.target_pid,
                                new_program_number: rule.target_program_number
                            });
                    });
                    this.streamCategoryRules = [
                        {
                            id: 1,
                            action: this.pidMappingProfile.default_action,
                            program_number: null,
                            category: "video",
                            new_pid: null
                        }
                    ];
                    this.streamTypeRules = [
                        {
                            id: 1,
                            action: this.pidMappingProfile.default_action,
                            program_number: null,
                            type: "mpgv1",
                            new_pid: null
                        }
                    ];
                }
                if (this.pidMappingProfile.rules.some(rule => rule.program_number != null)) this.program = "mpts";
                else this.program = "spts";
                //
                this.pcrRules = [];
                if (this.pidMappingProfile.pcr_on_video && typeof this.pidMappingProfile.pcr_on_video === "string") {
                    if (this.pidMappingProfile.pcr_on_video !== "-1") {
                        const s = this.pidMappingProfile.pcr_on_video;
                        const array = s.split(",");
                        for (const pcr of array) {
                            this.pcrRules.push({ program_number: parseInt(pcr, 10) });
                        }
                    } else {
                        this.pcrOnVideo = 1;
                    }
                }
            }
        } else if (!this.pidMappingProfile) {
            this.pidMappingProfile = new PIDMappingProfile();
            this.pidMappingProfile.name = null;
            this.pidMappingProfile.default_action = "pass";
            this.pidMappingProfile.type = "pid";
            // UI
            this.program = "spts";
            this.elementaryPIDRules = [
                {
                    id: 1,
                    action: this.pidMappingProfile.default_action,
                    program_number: null,
                    original_pid: null,
                    new_pid: null
                }
            ];
            this.streamCategoryRules = [
                {
                    id: 1,
                    action: this.pidMappingProfile.default_action,
                    program_number: null,
                    category: "video",
                    new_pid: null
                }
            ];
            this.streamTypeRules = [
                {
                    id: 1,
                    action: this.pidMappingProfile.default_action,
                    program_number: null,
                    type: "mpgv1",
                    new_pid: null
                }
            ];
            this.pmtRules = [];
        } else {
            this.tagsControl.setValue(this.pidMappingProfile.resourceTags);
        }

        // Set Title
        this.titleService.setTitle("PID_MAPPING_PROFILE", this.action, this.pidMappingProfile);
    }

    ngOnInit() {
        if (this.action === "edit") this.isEdit = true;
        if (this.action === "clone") this.isClone = true;

        this.pms.refreshPIDMappingProfiles();

        this.pidMappingProfilesSubscription = this.pms.pidMappingProfiles.subscribe(
            (pidMappingProfiles: PIDMappingProfile[]) => {
                this.pidMappingProfileNames = _.map(pidMappingProfiles, "name");
                if (this.isEdit) {
                    this.pidMappingProfileNames = _.without(this.pidMappingProfileNames, this.pidMappingProfileName);
                }
            }
        );

        this.prepForm();
    }

    ngOnDestroy() {
        this.pidMappingProfilesSubscription.unsubscribe();
    }

    defaultActionChanged() {
        if (this.isClone || this.isEdit) return;
        if (this.pidMappingProfile.type === "pid") {
            if (this.elementaryPIDRules.length === 1) {
                if (this.elementaryPIDRules[0].action) {
                    this.elementaryPIDRules[0].action = this.pidMappingProfile.default_action;
                    if (this.elementaryPIDRules[0].new_pid) this.elementaryPIDRules[0].new_pid = null;
                }
            }
        } else if (this.pidMappingProfile.type === "category") {
            if (this.streamCategoryRules.length === 1) {
                if (this.streamCategoryRules[0].action) {
                    this.streamCategoryRules[0].action = this.pidMappingProfile.default_action;
                    if (this.streamCategoryRules[0].new_pid) this.streamCategoryRules[0].new_pid = null;
                }
            }
        } else {
            if (this.streamTypeRules.length === 1) {
                if (this.streamTypeRules[0].action) {
                    this.streamTypeRules[0].action = this.pidMappingProfile.default_action;
                    if (this.streamTypeRules[0].new_pid) this.streamTypeRules[0].new_pid = null;
                }
            }
        }
    }

    deselectPCRRule(index: number) {
        this.pcrRules.splice(index, 1);
    }

    addPCRRule() {
        this.pcrRules.push({ program_number: null });
    }

    deselectElementaryPID(rule: PIDRule) {
        this.elementaryPIDRules = this.elementaryPIDRules.filter(i => {
            return i.id !== rule.id;
        });
    }

    addElementaryPID() {
        let max = Math.max.apply(
            null,
            this.elementaryPIDRules.map(rule => rule.id)
        );
        if (max < 0) max = 0;
        this.elementaryPIDRules.push({
            id: max + 1,
            action: this.pidMappingProfile.default_action,
            program_number: null,
            original_pid: null,
            new_pid: null
        });
    }

    deselectCategoryPID(rule: PIDRule) {
        this.streamCategoryRules = this.streamCategoryRules.filter(i => {
            return i.id !== rule.id;
        });
    }

    addCategoryPID() {
        let max = Math.max.apply(
            null,
            this.streamCategoryRules.map(rule => rule.id)
        );
        if (max < 0) max = 0;
        this.streamCategoryRules.push({
            id: max + 1,
            action: this.pidMappingProfile.default_action,
            program_number: null,
            category: "video",
            new_pid: null
        });
    }

    deselectTypePID(rule: PIDRule) {
        this.streamTypeRules = this.streamTypeRules.filter(i => {
            return i.id !== rule.id;
        });
    }

    addTypePID() {
        let max = Math.max.apply(
            null,
            this.streamTypeRules.map(rule => rule.id)
        );
        if (max < 0) max = 0;
        this.streamTypeRules.push({
            id: max + 1,
            action: this.pidMappingProfile.default_action,
            program_number: null,
            type: "mpgv1",
            new_pid: null
        });
    }

    deselectPMTRule(rule: PIDRule) {
        this.pmtRules = this.pmtRules.filter(i => {
            return i.id !== rule.id;
        });
    }

    addPMTRule() {
        let max = Math.max.apply(
            null,
            this.pmtRules.map(rule => rule.id)
        );
        if (max < 0) max = 0;
        this.pmtRules.push({
            id: max + 1,
            action: this.pidMappingProfile.default_action,
            program_number: null,
            type: "pmt",
            new_pid: null
        });
    }

    ruleActionChanged(rule: PIDRule) {
        if (rule && rule.action !== "map") {
            rule.new_pid = null;
        }
    }

    checkRulesExist(): boolean {
        if (
            this.program === "spts" &&
            this.pidMappingProfile.type === "pid" &&
            this.pmtRules.length === 0 &&
            this.elementaryPIDRules.length === 0
        )
            return false;
        if (
            this.program === "spts" &&
            this.pidMappingProfile.type === "category" &&
            this.pmtRules.length === 0 &&
            this.streamCategoryRules.length === 0
        )
            return false;
        if (
            this.program === "spts" &&
            this.pidMappingProfile.type === "type" &&
            this.pmtRules.length === 0 &&
            this.streamTypeRules.length === 0
        )
            return false;
        if (
            this.program === "mpts" &&
            this.pidMappingProfile.type === "pid" &&
            this.pcrRules.length === 0 &&
            this.pmtRules.length === 0 &&
            this.elementaryPIDRules.length === 0
        )
            return false;
        if (
            this.program === "mpts" &&
            this.pidMappingProfile.type === "category" &&
            this.pcrRules.length === 0 &&
            this.pmtRules.length === 0 &&
            this.streamCategoryRules.length === 0
        )
            return false;
        if (
            this.program === "mpts" &&
            this.pidMappingProfile.type === "type" &&
            this.pcrRules.length === 0 &&
            this.pmtRules.length === 0 &&
            this.streamTypeRules.length === 0
        )
            return false;

        return true;
    }

    async onSubmit() {
        if (!this.checkRulesExist()) return;

        const rules = [
            ...(this.pidMappingProfile.type === "pid"
                ? _.map(this.elementaryPIDRules, rule => {
                      return {
                          source: rule.original_pid,
                          action: rule.action,
                          program_number: rule.program_number,
                          target_pid: rule.new_pid
                      };
                  })
                : this.pidMappingProfile.type === "category"
                ? _.map(this.streamCategoryRules, rule => {
                      return {
                          source: rule.category,
                          action: rule.action,
                          program_number: rule.program_number,
                          target_pid: rule.new_pid
                      };
                  })
                : _.map(this.streamTypeRules, rule => {
                      return {
                          source: rule.type,
                          action: rule.action,
                          program_number: rule.program_number,
                          target_pid: rule.new_pid
                      };
                  })),
            ..._.map(this.pmtRules, rule => {
                return {
                    source: "pmt",
                    action: rule.action,
                    program_number: rule.program_number,
                    target_pid: rule.new_pid,
                    target_program_number: rule.new_program_number
                };
            })
        ];

        this.duplicateRulesError = !rules.every(rule => !(rules.filter(r => isEqual(r, rule)).length > 1));
        if (this.duplicateRulesError) return;

        this.saving = true;

        const model = {
            name: this.nameControl.value,
            resource_tag_ids: _.map(this.tagsControl.value, "id") as [number, ...number[]],
            default_action: this.pidMappingProfile.default_action,
            type: this.pidMappingProfile.type,
            rules,
            pcr_on_video:
                this.program === "spts" && this.pcrOnVideo
                    ? -1
                    : this.program === "mpts" && this.pcrRules.length > 0
                    ? _.map(this.pcrRules, rule => {
                          return rule.program_number;
                      }).toString()
                    : null
        };

        if (this.isEdit) {
            const rulesEqual = this.compareRules(rules, this.pidMappingProfile.rules);
            if (rulesEqual) delete model.rules;

            const result = await this.pms.updatePIDMappingProfile(this.pidMappingProfile, model);
            if (result)
                this.router.navigate([Constants.urls.transformation.pid_mapping_profiles, this.pidMappingProfile.name]);
        } else {
            const result = await this.pms.addPIDMappingProfile(model);
            if (result) this.router.navigate([Constants.urls.transformation.pid_mapping_profiles, model.name]);
        }

        this.saving = false;
    }

    cancel() {
        if (this.isEdit || this.isClone)
            this.router.navigate([Constants.urls.transformation.pid_mapping_profiles, this.pidMappingProfileName]);
        else this.router.navigate([Constants.urls.transformation.pid_mapping_profiles]);
    }

    compareRules(newRules, existingRules) {
        const rules1 = newRules.map(rule => ({ ...rule, target_program_number: rule.target_program_number || null }));
        const rules2 = existingRules.map(rule => ({ ...omit(rule, ["id", "pid_mapping_profile_id"]) }));

        const rulesEqual = isEqual(rules1, rules2);
        return rulesEqual;
    }
}
