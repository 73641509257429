import { Source, Tag } from "src/app/models/shared";
import { TargetPidMappingTypes } from "../channels/channel";

export class PIDMappingProfile {
    _frontData: {
        sortOrder: string;
        ruleCount?: number;
    };
    id: number;
    name: string;
    resource_tag_ids: number[];
    resourceTags: Tag[];
    type: "pid" | "type" | "category";
    default_action: "pass" | "set_null" | "remove";
    external_action: string;
    rules: Rules[];
    sources: Source[];
    created_at: string;
    updated_at: string;
    pcr_on_video: string | number;
    hasFullDetails?: boolean;
    targets: TargetPidMappingTypes[];
}

export class Rules {
    action: "pass" | "set_null" | "remove" | "map" | "map_range";
    id: number;
    pid_mapping_profile_id: number;
    program_number: number;
    source: string;
    target_pid: number;
    target_program_number: number;
}
